<template>
  <v-container>
    <v-expansion-panels v-model="acco" accordion>
      <v-expansion-panel v-for="(q, i) in qs" :key="i">
        <v-expansion-panel-header
          :color="empty_values.errors[i] ? '#ffaf954a' : 'white'"
        >
          <div class="d-flex align-center">
            <v-chip label color="#202020" class="white--text me-2">
              {{ i + 1 }}
            </v-chip>
            <h4>
              {{ q.text }}
            </h4>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-text-field
            hide-details
            class="mt-3"
            dense
            filled
            :rules="[(e) => !!e || 'حقل مطلوب']"
            v-model="answers[i].text"
            v-if="q.type == 'explain'"
          ></v-text-field>
          <v-select
            hide-details
            class="mt-3"
            :rules="[(e) => !!e || 'حقل مطلوب']"
            filled
            dense
            multiple
            v-model="answers[i].options"
            v-else-if="q.type == 'mcq'"
            :items="q.options"
            item-text="text"
            item-value="id"
          ></v-select>
          <v-alert
            dense
            outlined
            color="error lighten-4"
            class="white--text mt-3"
            v-if="
              related_q(q.related.related_id) &&
              (answers[i].text < qs[i].related.greater_than ||
                answers[i].text > qs[i].related.less_than)
            "
          >
            يجب ان تكون الاجابة اكبر من ({{ qs[i].related.greater_than }}) و
            اصغر من ({{ qs[i].related.less_than }})
          </v-alert>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-btn
      @click="save()"
      x-large
      class="mt-3"
      width="140"
      height="60"
      color="primary"
      :disabled="save_loader"
      :loading="save_loader"
      >حفظ</v-btn
    >

    <v-snackbar width="auto" right v-model="snackbar.prop">
      {{ snackbar.err ? snackbar.errText : snackbar.succText }}
      <template v-slot:action="{ attrs }">
        <v-icon
          :color="snackbar.err ? snackbar.errColor : snackbar.succColor"
          v-bind="attrs"
          >{{ snackbar.err ? "highlight_off" : "done" }}</v-icon
        >
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: "answers",
  data() {
    return {
      acco: 0,
      answers: [],
      questions: [],
      save_loader: false,
      snackbar: {
        prop: false,
        err: false,
        errColor: "red lighteen-2",
        succColor: "success",
        errText: "",
        succText: "تم التسجيل بنجاح",
      },
    };
  },
  computed: {
    qs() {
      let questions = [];
      this.questions.forEach((e, i) => {
        if (e.related_with_question) {
          if (this.answers.length != 0) {
            let find_related = this.questions.find(
              (q) => q.id == e.related.related_id
            );
            let find_answers = this.answers.findIndex(
              (an) => an.q_id == find_related.id
            );

            if (find_related.type == "mcq") {
              for (let x = 0; x < e.related.options.length; x++) {
                if (
                  this.answers[find_answers].options.includes(
                    e.related.options[x]
                  )
                ) {
                  questions.push(e);
                  break;
                } else {
                  this.answers[i].options = [];
                  this.answers[i].text = null;
                }
              }
            } else if (find_related.type == "explain") {
              if (this.answers[find_answers].text == e.related.value) {
                questions.push(e);
              } else {
                this.answers[i].options = [];
                this.answers[i].text = null;
              }
            }
          }
        } else {
          questions.push(e);
        }
      });
      return questions;
    },
    related_q: (app) => (related_id) => {
      if (related_id) {
        let find_q = app.questions.find((e) => e.id == related_id);
        return find_q;
      } else {
        return false;
      }
    },
    acive_qs() {
      let questions = [];

      this.qs.forEach((e, i) => {
        let f_q = this.answers.find((a) => a.q_id == e.id);
        if (f_q) {
          questions.push(f_q);
        }
      });

      return questions;
    },

    empty_values() {
      let errors = [];
      this.acive_qs.forEach((e, i) => {
        if (e.type == "explain") {
          errors[i] = !this.answers[i].text;
        } else if (e.type == "mcq") {
          errors[i] = this.answers[i].options.length == 0;
        }
      });
      return {
        errors: errors,
        is_valid: !errors.includes(true),
      };
    },
  },
  methods: {
    get_form() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: `forms/client_form/${this.$route.params.id}`,
          },
        })
        .then((res) => {
          this.form_name = res.data.name;
          this.form_id = res.data.id;
          this.questions = Object.assign([], res.data.questions);
          let answers = res.data.answers;
          this.questions.forEach((e, i) => {
            let find_answer =
              answers.length != 0
                ? answers.answers.find((q) => q.q_id == e.id)
                : null;
            if (!find_answer) {
              this.$set(this.answers, i, {
                id: null,
                q_id: e.id,
                options: [],
                text: null,
                type: e.type,
              });
            } else {
              let is_last = e.id == answers.last_answer;
              if (is_last) {
                this.steps = i + 1;
              }
              this.$set(this.answers, i, {
                id: find_answer.id,
                q_id: e.id,
                options: find_answer.options,
                text: find_answer.text,
                last_answer: is_last,
                type: e.type,
              });
            }
          });
          if (!answers.last_answer) {
            this.answers[0].last_answer = true;
          } else {
            this.steps = this.last_q_index + 1;
          }
          this.data_loaded = true;
        })
        .catch((err) => {
          // this.$router.push("/occasions");
          console.log(err.response)
        });
    },
    save() {
      if (this.empty_values.is_valid) {
        if (!this.save_loader) {
          this.save_loader = true;
          this.$store
            .dispatch("public__request", {
              config: {
                url: "forms/save_answers",
                method: "post",
              },
              data: {
                occasion_id: this.$route.params.id,
                answers: this.answers,
              },
            })
            .then((res) => {
              this.snackbar.prop = true;
              this.snackbar.err = false;
              this.snackbar.succText = "تم تحديث الاجابات بنجاح";
              setTimeout(() => {
                this.$router.push("/occasions");
              }, 1300);
              console.log(res);
            })
            .catch((err) => {
              console.log(err.response);
            });
        }
      } else {
        this.snackbar.prop = true;
        this.snackbar.err = true;
        this.snackbar.errText = "برجاء ملئ الاجابات المطلوبة"
      }
    },
  },
  created() {
    this.get_form();
  },
};
</script>
